const isProduction = process.env.NODE_ENV === "production"

/**
 * Build an APIFlash link to generate a screenshot for a given webpage
 * @param {string} url URL of desired page
 * @returns {string} APIFlash link
 */
export function buildScreenshotLink(url) {
  url.toString()
  if (!url || url.length < 4) {
    return null
  }

  const link = `https://api.apiflash.com/v1/urltoimage?access_key=${process.env.GATSBY_APIFLASH_KEY}&url=${url}&format=png&width=1200&height=630&response_type=image`

  return link
}

/**
 * Convert Sanity Portable Text to plain text
 * @param {array} blocks Portable Text blocks
 * @returns {string} plain text
 */
export function portableToPlainText(blocks = []) {
  if (!blocks || blocks.length < 1) {
    console.log(blocks)
    return ""
  }

  return (
    blocks
      // loop through each block
      .map(block => {
        // if it's not a text block with children,
        // return nothing
        if (block._type !== "block" || !block.children) {
          return ""
        }
        // loop through the children spans, and join the
        // text strings
        return block.children.map(child => child.text).join("")
      })
      // remove empty array elements
      .filter(el => el !== "")
      // join the paragraphs leaving split by one linebreak
      .join("\n\n")
  )
}

/**
 * Generate an excerpt for a given set of text
 * @param {string} content String to be excerpted
 * @param {int} length Target character length
 * @returns {string} Excerpt
 */
export function generateExcerpt(content, length = 150) {
  const filter = content.toString().replace(/\s+/g, " ")
  let shortenedString = filter.substr(0, length - 3)

  if (shortenedString.trim() === shortenedString) {
    const lastIndex = shortenedString.lastIndexOf(" ")
    shortenedString = shortenedString.substr(0, lastIndex)
  }

  const excerpt = shortenedString.trim() + "..."

  return excerpt
}

/**
 * Estimate how long a piece of text will take to read
 * @param {string} content Content to estimate
 * @param {integer} wordsPerMinute Baseline
 * @returns {string} Estimamted reading time
 */
export function calculateTimeToRead(content, wordsPerMinute = 225) {
  const words = content.toString().split(/\s+/).length
  const time = Math.ceil(words / wordsPerMinute)

  // Return time (in minutes)
  return time
}

/**
 * Standardize a link for a given path
 * @param {string} path Path to clean
 * @param {string} type Path type
 * @returns {string} Generated path
 */
export function generatePath(path, type = "") {
  let generatedPath = path.toString().trim()

  // Make sure path != '/'
  if (path.length > 1) {
    if (!generatedPath.startsWith("/")) {
      generatedPath = "/" + generatedPath
    }
    if (!generatedPath.endsWith("/")) {
      generatedPath += "/"
    }
  }

  if (type === "person") {
    generatedPath = "/people" + generatedPath
  }

  return generatedPath
}

/**
 * Form a string with a person's name and honorific
 * @param {string} name Person's name
 * @param {string} honorific Person's honorific
 * @returns {string} Name with honorific
 */
export function generateNameWithHonorific(name, honorific = "") {
  let nameWithHonorific = (honorific ? honorific + " " : "") + name

  return nameWithHonorific
}

/**
 * Check if an edition has been released
 * @param {string} release ISO timestamp
 * @param {string} status Release status
 * @returns {bool} If both requirements are met, the edition has been released
 */
export function editionIsReleased(release, status) {
  if (!isProduction) {
    return true
  }

  const currentISOTime = new Date().toISOString()
  const publishableStatuses = ["approved", "archived"]

  const timeCheck = currentISOTime >= release
  const statusCheck = publishableStatuses.includes(status)

  return timeCheck && statusCheck
}
