exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archives-js": () => import("./../../../src/pages/archives.js" /* webpackChunkName: "component---src-pages-archives-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-editions-js": () => import("./../../../src/pages/editions.js" /* webpackChunkName: "component---src-pages-editions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/articleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-edition-template-js": () => import("./../../../src/templates/editionTemplate.js" /* webpackChunkName: "component---src-templates-edition-template-js" */),
  "component---src-templates-person-template-js": () => import("./../../../src/templates/personTemplate.js" /* webpackChunkName: "component---src-templates-person-template-js" */),
  "component---src-templates-screenshot-article-screenshot-template-js": () => import("./../../../src/templates/screenshot/articleScreenshotTemplate.js" /* webpackChunkName: "component---src-templates-screenshot-article-screenshot-template-js" */),
  "component---src-templates-screenshot-edition-screenshot-template-js": () => import("./../../../src/templates/screenshot/editionScreenshotTemplate.js" /* webpackChunkName: "component---src-templates-screenshot-edition-screenshot-template-js" */)
}

