import React from "react"
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav"
import { createGlobalStyle } from "styled-components"
import styled from "styled-components"
import Sidebar from "./Sidebar"
import Footer from "./Footer"

export const Layout = ({ children, pageContext, location }) => {
  if (pageContext.layout === "screenshot") {
    return (
      <>
        <GlobalStyle />
        <main>{children}</main>
      </>
    )
  }

  return (
    <>
      <SkipNavLinkComponent
        className="custom"
        style={{ zIndex: 9999, borderRadius: `5px` }}
      />
      <GlobalStyle />
      <TwoCol>
        <Sidebar location={location} pageContext={pageContext} />
        <div>
          <SkipNavContent />
          <main>{children}</main>
          <Footer />
        </div>
      </TwoCol>
    </>
  )
}

const GlobalStyle = createGlobalStyle`
  :root {
    --maroon: #501111;
    --maroon-rgb: 80, 17, 17;
    --gold: #f0b01d;
    --gold-rgb: 240, 176, 29;
    --gray: rgb(105, 105, 105);

    --white-rgb: 245, 245, 245;

    --light100: rgb(245, 245, 245);
    --mid-white: rgb(225, 225, 225);

    --font-sans-serif: "Lato",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    --font-serif: "alexander-quill",Times,serif;

    --page-background: rgb(245, 245, 245);
    --page-color: rgb(18, 1, 1);

    --headers-color: var(--maroon);

    --link-color: var(--maroon);
    --link-hover-color: var(--light100);

    --selection-background: rgba(var(--maroon-rgb), 0.85);
    --selection-color: var(--light100);

    --reach-skip-nav: 1;
  }

  html, body {
    box-sizing: border-box;
    height: 100%; 
  }

  * {
    margin: 0;
    padding: 0;
  }

  body {
    background-color: var(--page-background);
    color: var(--page-color);
    font-family: var(--font-sans-serif);
    transition: background 0.25s ease-in-out;
  }

  [data-reach-skip-nav-link] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    position: absolute;
  }

  [data-reach-skip-nav-link]:focus {
    padding: 1rem;
    position: fixed;
    top: 10px;
    left: 10px;
    background: white;
    z-index: 1;
    width: auto;
    height: auto;
    clip: auto;
  }

  img {
    width: 100%;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-serif);
    color: var(--headers-color);
  }

  h1 {
    font-size: 35px;
  }

  main {
    margin: 0;
    max-width: 650px;
    padding: 0 1.5rem 0 1.5rem;
    transition: all .15s ease;

    @media only screen and (min-width: 767px) {
      padding: 7rem 2rem 0 2rem;
    }

    @media only screen and (min-width: 1025px) {
      margin: 0 0 0 2vw;
    }

    @media only screen and (min-width: 1201px) {
      margin: 0 0 0 calc(6vw + 2.5rem);
    }
  }

  b {
    font-weight: 700;
  }

  a {
    text-decoration: none;
    color: currentColor;

    &.custom {
      outline: none;
    }

    &:not(.custom):not(.button) {
      background: linear-gradient(to bottom, rgba(var(--maroon-rgb), 0.85) 0%, rgba(var(--maroon-rgb), 0.85) 100%);
      background-position: 0 100%;
      background-repeat: repeat-x;
      background-size: 0 0;
      transition: all .35s ease;
      border-radius: 2px;
      padding: 0 2px;
      color: var(--link-color);

      &:hover {
        color: var(--light100);
        background-size: 4px 50px;
      }
    }
  }

  button, .button {
    background-color: var(--maroon);
    outline: none;
    border: 2px solid transparent;
    border-radius: 5px;
    color: var(--light100);
    padding: .5rem 1.25rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-family: var(--font-sans-serif);
    font-size: 14px;
    cursor: pointer;
    transition: all .15s ease;

    &:hover {
      background-color: rgba(var(--maroon-rgb), .85);
    }

    &:focus-visible {
      box-shadow: var(--gold) 0 0 0 3px;
    }

    &.full {
      width: 100%;
    }

    &.focus {
      border: 2px solid transparent;

      &:active {
        border: 2px solid var(--gold);
      }
    }

    &.icon {
      > span {
        display: inline-block;
        margin-left: .325rem;
        will-change: transform;
        transform: translateX(0);
        transition: transform .375s ease;
      }
      
      &:hover > span, &:focus-visible > span {
        transform: translateX(.45rem);
        transition: transform .25s ease-out
      }
    }
  }

  label {
    font-size: .8em;
  }

  input, textarea {
    width: 100%;
    margin: .25rem 0 .5rem 0;
    padding: 12px;
    outline: none;
    border: 2px solid transparent;
    border-radius: 5px;
    background-image: none;
    background-color: var(--mid-white);
    box-shadow: none;
    box-sizing: border-box;
    font-family: var(--font-sans-serif);
    font-size: 14px;
    transition: border-color .15s ease;

    &:focus {
      border-color: var(--maroon);
    }
  }

  textarea {
    min-height: 45px;
    resize: vertical;
  }

  input[type=text] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  *::selection {
    background: var(--selection-background);
    color: var(--selection-color);
  }

  [data-rmiz-wrap] {
    line-height: 0;
  }

  [data-rmiz-btn-open] {
    cursor: url("/assets/icons/zoom-in.svg"), zoom-in !important;
  }

  [data-rmiz-btn-close] {
    cursor: url("/assets/icons/zoom-out.svg"), zoom-in !important;
  }

  [data-rmiz-wrap='visible'],
  [data-rmiz-wrap='hidden'] {
    width: 100%;
    height: 100%;
    display: block;
  }

  [data-rmiz-btn-open]:hover {
    background: unset;
  }
  [data-rmiz-btn-close]:hover {
    background: unset;
  }

  [data-rmiz-modal-content] * {
    border-radius: 5px;
  }
`

const SkipNavLinkComponent = styled(SkipNavLink)``

const TwoCol = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1rem;
  align-items: flex-start;

  @media only screen and (min-width: 767px) {
    grid-template-columns: min-content auto;
    grid-gap: 2rem;
  }
`

export default Layout
