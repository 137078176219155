import React, { useState, useEffect, Fragment } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { globalHistory } from "@reach/router"
import { generatePath, editionIsReleased } from "../lib/helpers"
import styled from "styled-components"
import BackButton from "./BackButton"
import FadeIn from "./FadeIn"
import Logo from "./svg/Logo"
import MenuOpen from "./svg/MenuOpen"
import MenuClose from "./svg/MenuClose"

export default function Sidebar({ pageContext }) {
  const data = useStaticQuery(graphql`
    query($editionId: String) {
      editions: allSanityEdition(sort: { fields: [release], order: DESC }) {
        edges {
          node {
            _id
            name
            release
            formattedRelease: release(formatString: "MMMM D, YYYY")
            simplifiedRelease: release(formatString: "YYYY/MM")
            slug {
              current
            }
            status
          }
        }
      }

      articles: allSanityArticle(
        filter: { edition: { _id: { eq: $editionId } } }
        sort: { fields: [sequence, title], order: ASC }
      ) {
        edges {
          node {
            _id
            title
            slug {
              current
            }
            edition {
              _id
              name
              release(formatString: "MMMM D, YYYY")
              slug {
                current
              }
            }
            status
          }
        }
      }
    }
  `)

  const { editions, articles } = data
  const releasedEditions = editions.edges.filter(({ node }) => {
    return editionIsReleased(node.release, node.status)
  })

  const [expanded, setExpanded] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  const handleExpansion = () => {
    setExpanded(!expanded)
  }

  useEffect(() => {
    window.onscroll = function () {
      if (window.pageYOffset > 5) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }

    return function cleanup() {
      window.onscroll = null
    }
  })

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        setExpanded(false)
      }
    })
  }, [expanded])

  return (
    <SidebarComponent
      className={[scrolled ? "scrolled" : "", expanded ? "expanded" : ""].join(
        " "
      )}
    >
      <TopRow>
        <Link
          to={generatePath("/", "page")}
          className="custom"
          aria-label="Home Page"
        >
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
        </Link>

        <Control>
          <Button
            type="button"
            className={expanded ? "expanded" : ""}
            onClick={handleExpansion}
          >
            {!expanded ? <MenuOpen /> : <MenuClose />}
          </Button>
        </Control>
      </TopRow>

      <Content className={expanded ? "expanded" : ""}>
        {pageContext.layout !== "article" && pageContext.layout !== "edition" && (
          <FadeIn duration={750}>
            <Editions>
              <Link
                to={generatePath("/", "page")}
                activeClassName="active"
                className="custom"
              >
                <Edition>
                  <Title>About the Press</Title>
                  <Subtitle>Our background, mission, and team</Subtitle>
                </Edition>
              </Link>

              <Link
                to={generatePath("/editions", "page")}
                activeClassName="active"
                className="custom"
              >
                <Edition>
                  <Title>Editions</Title>
                  <Subtitle>Explore our most recent releases</Subtitle>
                </Edition>
              </Link>

              <Link
                to={generatePath("/people", "page")}
                activeClassName="active"
                className="custom"
                partiallyActive={true}
              >
                <Edition>
                  <Title>People</Title>
                  <Subtitle>Everyone who has contributed to the Press</Subtitle>
                </Edition>
              </Link>

              <Link
                to={generatePath("/archives", "page")}
                activeClassName="active"
                className="custom"
              >
                <Edition>
                  <Title>Archives</Title>
                  <Subtitle>Our online time machine</Subtitle>
                </Edition>
              </Link>

              <Link
                to={generatePath("/contact", "page")}
                activeClassName="active"
                className="custom"
              >
                <Edition>
                  <Title>Contact Us</Title>
                  <Subtitle>We'd love to hear from you!</Subtitle>
                </Edition>
              </Link>
            </Editions>
          </FadeIn>
        )}

        {pageContext.layout === "edition" && (
          <FadeIn duration={750}>
            <Editions>
              {releasedEditions.map(({ node }) => (
                <Link
                  to={generatePath(node.slug.current, "edition")}
                  activeClassName="active"
                  key={node._id}
                  className="custom"
                >
                  <Edition>
                    <Title>{node.name}</Title>
                    <Subtitle>{node.formattedRelease}</Subtitle>
                  </Edition>
                </Link>
              ))}
            </Editions>
          </FadeIn>
        )}

        {pageContext.layout === "article" && (
          <FadeIn duration={750}>
            <Editions>
              {releasedEditions
                .filter(edge => {
                  // Only map editions that match the current page's editionId
                  return edge.node._id === pageContext.editionId
                })
                .map(({ node }) => (
                  <Fragment key={node.id}>
                    <AllEditions>
                      <BackButton
                        to={generatePath("/editions", "page")}
                        label="All Editions"
                        className="custom"
                      />
                    </AllEditions>
                    <Link
                      to={generatePath(node.slug.current, "edition")}
                      className="custom"
                    >
                      <Edition>
                        <Title>{node.name}</Title>
                        <Subtitle>{node.formattedRelease}</Subtitle>
                      </Edition>
                    </Link>

                    <Articles>
                      {articles.edges
                        .filter(edge => {
                          // Only map articles that match the current page's editionId
                          return edge.node.edition._id === pageContext.editionId
                        })
                        .map(({ node }) => (
                          <Link
                            to={generatePath(node.slug.current, "article")}
                            activeClassName="active"
                            className="custom"
                            key={node._id}
                          >
                            <Article>
                              <p>{node.title}</p>
                            </Article>
                          </Link>
                        ))}
                    </Articles>
                  </Fragment>
                ))}
            </Editions>
          </FadeIn>
        )}
      </Content>
    </SidebarComponent>
  )
}

const SidebarComponent = styled.header`
  --background: var(--maroon);
  --background-rgb: var(--maroon-rgb);
  --text: var(--light100);
  --text-rgb: var(--white-rgb);
  --active-accent: var(--light100);
  position: sticky;
  top: 0;
  z-index: 99;
  padding: 1.5rem 0;
  width: 100%;
  background-color: var(--background);
  transition: box-shadow 0.15s ease-in-out;

  &.scrolled {
    box-shadow: var(--mid-white) 0 0 10px 3px;
  }

  @media only screen and (max-width: 766px) {
    margin-bottom: 1rem;

    &.expanded {
      padding: 1.5rem 0 0 0;
      height: calc(100vh - 1.5rem);
      overflow-y: auto;
    }
  }

  @media only screen and (min-width: 767px) {
    padding: 0 0 2rem 0;
    width: min-content;
    height: calc(100vh - 2rem);
    overflow-y: auto;

    &.scrolled {
      box-shadow: none;
    }
  }
`

const TopRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
`

const Control = styled.div`
  padding: 0 1.5rem;
  justify-self: end;

  &.expanded {
    padding: 1.5rem;
  }

  @media only screen and (min-width: 767px) {
    display: none;
  }
`

const Button = styled.button`
  display: flex;
  align-items: center;
  border: 2px solid rgba(var(--text-rgb), 0.5);

  &:hover {
    border-color: var(--text);
  }

  svg {
    stroke: var(--text);
  }

  &.expanded {
    background-color: transparent;

    svg {
      stroke: var(--text);
    }
  }
`

const Content = styled.div`
  display: none;
  margin-top: 2rem;

  &.expanded {
    display: block;
  }

  @media only screen and (min-width: 767px) {
    margin-top: 0;
    display: block;
  }
`

const LogoWrapper = styled.div`
  display: block;
  z-index: 99;
  padding: 0 1.5rem;
  background-color: var(--background);
  border: 3px solid transparent;
  transition: background-color 0.15s ease;

  &:hover {
    background-color: rgba(var(--text-rgb), 0.075);
  }

  a:focus-visible & {
    background-color: rgba(var(--text-rgb), 0.075);
    border-color: var(--gold);
  }

  svg {
    height: 55px;
    width: auto;
    fill: var(--text);
  }

  @media only screen and (min-width: 767px) {
    position: sticky;
    top: 0;
    padding: 2rem;
  }
`

const Editions = styled.nav`
  .active {
    & > div {
      border-left-color: var(--active-accent);
    }
  }
`

const Edition = styled.div`
  padding: 1rem 1.5rem 1rem calc(1.5rem - 3px);
  border: 3px solid transparent;
  cursor: pointer;
  transition: all 0.15s ease;

  &:hover {
    background-color: rgba(var(--text-rgb), 0.075);
  }

  a:focus-visible & {
    background-color: rgba(var(--text-rgb), 0.075);
    border-color: var(--gold);
  }

  @media only screen and (min-width: 767px) {
    padding: 1rem 2rem 1rem calc(2rem - 3px);
  }
`

const Title = styled.p`
  color: var(--text);
  font-size: 1.3em;
  font-weight: bold;
`

const Subtitle = styled.p`
  padding: 0;
  margin: 0;
  margin-top: 0.35rem;
  color: var(--mid-white);
  transition: color 0.15s ease;
`

const AllEditions = styled.div`
  display: block;

  a {
    padding: 0.5rem 1.5rem 0.5rem calc(1.5rem - 3px + 5px);
    border: 3px solid transparent;
    color: var(--mid-white);

    @media only screen and (min-width: 767px) {
      padding: 0.5rem 2rem 0.5rem calc(2rem - 3px + 5px);
    }

    &:hover {
      color: var(--text);
      background-color: rgba(var(--text-rgb), 0.075);
    }

    &:focus-visible {
      background-color: rgba(var(--text-rgb), 0.075);
      border-color: var(--gold);
      color: var(--text);
    }
  }
`

const Articles = styled.div`
  .active {
    & > div {
      border-left-color: var(--active-accent);
      color: var(--text);
    }
  }
`

const Article = styled.div`
  padding: 0.75rem 1.5rem 0.75rem calc(1.5rem - 3px);
  border: 3px solid transparent;
  cursor: pointer;
  transition: background-color 0.15s ease;
  color: var(--text);
  font-size: 1.05em;

  &:hover {
    background-color: rgba(var(--text-rgb), 0.075);
  }

  a:focus-visible & {
    background-color: rgba(var(--text-rgb), 0.075);
    border-color: var(--gold);
  }

  @media only screen and (min-width: 767px) {
    padding: 0.75rem 2rem 0.75rem calc(2rem - 3px);
  }
`
