import React from "react"
import styled, { keyframes } from "styled-components"

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(1.15em);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-1.15em);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const Wrapper = styled.div`
  @media (prefers-reduced-motion: no-preference) {
    will-change: opacity, transform;
    animation-name: ${fadeIn};
    animation-fill-mode: backwards;
  }
`

const WrapperUp = styled.div`
  @media (prefers-reduced-motion: no-preference) {
    will-change: opacity, transform;
    animation-name: ${fadeInUp};
    animation-fill-mode: backwards;
  }
`

const WrapperDown = styled.div`
  @media (prefers-reduced-motion: no-preference) {
    will-change: opacity, transform;
    animation-name: ${fadeInDown};
    animation-fill-mode: backwards;
  }
`

const FadeIn = ({
  duration = 500,
  delay = 0,
  direction = "none",
  children,
  ...delegated
}) => {
  return (
    <>
      {direction === "none" && (
        <Wrapper
          {...delegated}
          style={{
            ...(delegated.style || {}),
            animationDuration: duration + "ms",
            animationDelay: delay + "ms",
          }}
        >
          {children}
        </Wrapper>
      )}

      {direction === "up" && (
        <WrapperUp
          {...delegated}
          style={{
            ...(delegated.style || {}),
            animationDuration: duration + "ms",
            animationDelay: delay + "ms",
          }}
        >
          {children}
        </WrapperUp>
      )}

      {direction === "down" && (
        <WrapperDown
          {...delegated}
          style={{
            ...(delegated.style || {}),
            animationDuration: duration + "ms",
            animationDelay: delay + "ms",
          }}
        >
          {children}
        </WrapperDown>
      )}
    </>
  )
}

export default FadeIn
