import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const LinkBack = styled(Link)`
  display: flex;
  align-items: center;
  color: var(--gray);
  text-decoration: none;
  transition: all 0.25s ease-in-out;

  &:hover {
    color: var(--link-color);
    background: transparent;

    & > span {
      transform: rotate(135deg) scale(1.3);
    }
  }
`

const BackArrow = styled.span`
  display: inline-block;
  margin-right: 0.5em;
  padding: 2px;
  border: solid currentColor;
  border-width: 0 2px 2px 0;
  transform: rotate(135deg) scale(1.1);
  transition: transform 0.2s ease;
`

function BackButton(props) {
  return (
    <LinkBack to={props.to} className="custom">
      <BackArrow />
      {props.label}
    </LinkBack>
  )
}

export default BackButton
