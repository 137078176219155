import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

function Footer() {
  const data = useStaticQuery(graphql`
    query {
      settings: sanitySettings(_id: { eq: "site-settings" }) {
        siteTitle
        disclaimer
      }
    }
  `)

  const { settings } = data

  return (
    <FooterSection>
      <Left>
        <p>
          &copy; {new Date().getFullYear()}, {settings.siteTitle} &mdash; All
          Rights Reserved
        </p>
        <p>
          Site designed + built by{" "}
          <a
            href="https://jaysella.dev"
            target="_blank"
            rel="noopener noreferrer"
            className="custom"
          >
            Jay Sella, '21
          </a>
        </p>
      </Left>
      <Right>{settings.disclaimer}</Right>
    </FooterSection>
  )
}

export default Footer

const FooterSection = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 3rem 1rem 1rem;
  border-top: 2px solid var(--mid-white);
  padding: 1.5rem 0 2rem 0;
  font-size: 0.9em;
  transition: all 0.25s ease-in-out;

  @media only screen and (min-width: 767px) {
    flex-direction: row;
    margin: 7.5rem 2rem 3rem;
    padding: 1rem 0 0 0;
  }
`

const Left = styled.div`
  text-align: center;
  display: grid;
  grid-gap: 0.25rem;

  p:last-of-type {
    color: var(--gray);

    a {
      transition: color 0.15s ease;
      color: var(--maroon);
      outline: revert;

      &:hover {
        color: var(--maroon);
        text-decoration: underline;
      }

      &:focus-visible {
        color: var(--maroon);
      }
    }
  }

  @media only screen and (min-width: 767px) {
    text-align: left;

    p:last-of-type {
      a {
        color: var(--gray);
      }
    }
  }
`

const Right = styled.p`
  display: flex;
  margin: 1.5rem 0 0 0;
  text-align: center;
  transition: all 0.25s ease-in-out;
  color: var(--gray);

  @media only screen and (min-width: 767px) {
    margin: 0 0 0 3rem;
    width: 60ch;
    text-align: right;
  }
`
